import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import withTheme from '@material-ui/core/styles/withTheme';

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit,
    },
}))(MuiDialogActions);

class DynaDigit extends React.Component {
    increase = () => {
        this.props.onChange(this.props.value + 1);
    };

    decrease = () => {
        this.props.onChange(this.props.value - 1);
    };

    render = () => {
        const { value, min, max } = this.props;
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Button onClick={this.increase} size={'small'} variant={'contained'} color={'secondary'}>
                    {'\u02C4'}
                </Button>
                <div>{value}</div>
                <Button onClick={this.decrease} size={'small'} variant={'contained'} color={'secondary'}>
                    {'\u02C5'}
                </Button>
            </div>
        );
    };
}


const Clock = withTheme()(class extends React.Component {
    state = {};
    static getDerivedStateFromProps(props, state) {
        return {
            date: new Date(props.ms),
        };
    }

    handleChange = (setFn, processor) => async value => {
        console.log(value);
        if ('function' === typeof processor) value = processor(value);

        let date = this.state.date;
        setFn.call(date, value);

        try {
            await this.props.onChange(date.getTime());
        } catch (e) {
            this.setState({ error: e.message });
        }
    };

    render = () => {
        const {ms, theme} = this.props;
        const { date, error } = this.state;

        let tainted = ms !== date.getTime();

        return (
            <div>
                <div style={{
                    fontSize: '.8em',
                    textAlign: 'center',
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.getContrastText(theme.palette.primary.main)
                }}>
                    {date.getFullYear()}-
                    {('0' + (date.getMonth() + 1)).slice(-2)}-
                    {('0' + (date.getDate() + 1)).slice(-2)}
                </div>
                <div style={{display: 'flex', placeItems: 'center', padding: 8}}>
                    <DynaDigit value={date.getHours()} onChange={this.handleChange(date.setHours)} />
                    <div>:</div>
                    <DynaDigit value={date.getMinutes()} onChange={this.handleChange(date.setMinutes)} />
                    {/*<DynaDigit value={date.getSeconds()} onChange={this.handleChange(date.setSeconds)} />*/}
                </div>
                {error && error}
            </div>
        );
    };
});

const styles = {
    root: {
        minWidth: '100%',
    },
    label: {
        display: 'flex',
        flexDirection: 'column',
    },
};

const WorkloadEditor = withStyles(styles)(
    class extends React.Component {
        state = {
            open: false,
        };

        handleClickOpen = () => {
            this.setState({
                open: true,
            });
        };

        handleClose = () => {
            this.setState({open: false});
        };

        handleChange = oldTime => async newTime => {
            const {projectId, user} = this.props;
            let times = Object.keys(this.props.project.workload).map(x => parseInt(x));
            let index = times.indexOf(oldTime);
            let prev;
            let next;

            if (index === 0) {
                prev = newTime - 1;
            } else {
                prev = times[index - 1];
            }

            if (index === times.length - 1) {
                next = newTime + 1;
            } else {
                next = times[index + 1];
            }

            if (newTime > prev && newTime < next) {
                let oldData = this.props.project.workload[oldTime];
                await window.firebase
                    .database()
                    .ref(`/users/${user.uid}/projects/${projectId}/workload/${oldTime}`)
                    .set(null);
                await window.firebase
                    .database()
                    .ref(`/users/${user.uid}/projects/${projectId}/workload/${newTime}`)
                    .set(oldData);
            } else {
                throw Error('Naaah');
            }
        };

        render() {
            const {project, projectId, workloadPairs, buttonComponent, classes} = this.props;
            return (
                <div>
                    <Button
                        classes={{label: classes.label, root: classes.root}}
                        variant="outlined"
                        color="secondary"
                        onClick={this.handleClickOpen}>
                        {React.createElement(buttonComponent)}
                    </Button>
                    <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.open}>
                        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                            Month Break Down
                        </DialogTitle>
                        <DialogContent>
                            {workloadPairs.map(({start, end}) => (
                                <div key={start.time} style={{display: 'flex', alignItems: 'center'}}>
                                    <Clock ms={start.time} onChange={this.handleChange(start.time)}/>
                                    <Clock ms={end.time} onChange={this.handleChange(end.time)}/>
                                </div>
                            ))}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }
    },
);

export default WorkloadEditor;
