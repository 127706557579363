import React from 'react';
import Fab from '@material-ui/core/Fab';
import PauseCircleOutline from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import Rodiv from '../Rodiv/Rodiv';
import Button from '@material-ui/core/Link';
import ProjectMonth from './ProjectMonth';

class Editable extends React.Component {
    state = {
        edit: false,
    };

    elRef = React.createRef();

    handleBlur = e => {
        this.props.onChange(e.target.innerText);
    };

    handleClick = e => {
        this.setState({ edit: true }, () => this.elRef.current.focus());
    };

    render = () => {
        const { text } = this.props;
        const { edit } = this.state;
        return (
            <div
                ref={this.elRef}
                onBlur={this.handleBlur}
                contentEditable={edit}
                suppressContentEditableWarning={true}
                onClick={this.handleClick}
                style={{
                    fontWeight: 'bold',
                    color: this.state.rename ? 'orange' : 'inherit',
                    display: 'inline-block',
                }}>
                {text}
            </div>
        );
    };
}

class Project extends React.Component {
    state = {
        showTags: false,
        now: Date.now(),
    };

    handleClick = () => {
        const { project } = this.props;
        let atWork;
        try {
            [, { onduty: atWork }] = Object.entries(project.workload).slice(-1)[0];
        } catch (_) {
            // no previous workload found in storage
            atWork = false;
        }

        this.props.onWorkload([Date.now(), !atWork]);
    };

    toggleTagView = () => {
        this.setState({ showTags: !this.state.showTags });
    };

    selectTag = tag => e => {
        this.setState({ showTags: false }, () => {
            this.props.onTagSelected(tag);
        });
    };

    clearSelectedTag = () => {
        this.props.onClearSelectedTag();
    };

    static getDerivedStateFromProps(props, _state) {
        let { now, ...state } = _state;

        try {
            const [timestamp, { onduty: atWork }] = Object.entries(props.project.workload).slice(-1)[0];
            return { ...state, timestamp, atWork, now };
        } catch (_) {
            // No previous workload found in storage
            return _state;
        }
    }

    handleClose = e => {
        this.setState({ showTags: false });
    };

    projectName = React.createRef();

    refresh = () => {
        this.setState({ now: Date.now() });
        setTimeout(this.refresh, 1000);
    };

    componentDidMount = () => {
        setTimeout(this.refresh, 1000);
    };

    renameProject = e => {};

    executeUpdate = (fbRef, valueHandler) => async value => {
        const { projectId, user } = this.props;

        if('function' === typeof(valueHandler)) {
            value = valueHandler(value);
        }

        await window.firebase
            .database()
            .ref(fbRef)
            .set(value);

        this.setState({ rename: false });
    };

    render = () => {
        const { project, user, projectId } = this.props;
        const date = new Date();
        const thisMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);

        let { timestamp, atWork, now } = this.state;
        let timeAtWork = null;

        if (!isNaN(timestamp)) {
            let dateObj = new Date(now - timestamp);
            timeAtWork = `${('0' + dateObj.getUTCHours()).slice(-5)}:${('0' + dateObj.getUTCMinutes()).slice(-2)}:${(
                '0' + dateObj.getUTCSeconds()
            ).slice(-2)}`;
        }

        return (
            <React.Fragment>
                <Rodiv
                    variant={'caption'}
                    style={{
                        alignContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <div style={{display: 'grid'}}>
                        <Editable text={project.name} onChange={this.executeUpdate(`/users/${user.uid}/projects/${projectId}/name`)} />
                        <div>Hourly rate: <Editable text={project.rate} onChange={this.executeUpdate(`/users/${user.uid}/projects/${projectId}/rate`, val => parseInt(val))} /></div>


                        {/*<IconButton onClick={this.toggleTagView}>*/}
                            {/*<MoreVert fontSize={'small'} />*/}
                        {/*</IconButton>*/}
                        {/*<Menu anchorEl={this.projectName.current} open={this.state.showTags} onClose={this.handleClose}>*/}
                            {/*<MenuItem>*/}
                                {/*<IconButton*/}
                                    {/*onClick={() => {*/}
                                        {/*this.setState({ rename: true }, () => this.projectName.current.focus());*/}
                                    {/*}}>*/}
                                    {/*<Edit fontSize={'small'} />*/}
                                {/*</IconButton>*/}
                            {/*</MenuItem>*/}
                            {/*))}*/}
                        {/*</Menu>*/}
                        {project.selectedTag &&
                            project.selectedTag.length > 0 && (
                                <div style={{ paddingLeft: 8 }}>
                                    {project.selectedTag} <Button onClick={this.clearSelectedTag}>[x]</Button>
                                </div>
                            )}
                    </div>
                    {this.state.showTags && (
                        <Rodiv
                            style={{
                                paddingLeft: 8,
                            }}>
                            {project.tags &&
                                project.tags.filter(tag => tag !== project.selectedTag).map(tag => (
                                    <div key={tag}>
                                        <Button onClick={this.selectTag(tag)} variant={'caption'}>
                                            {tag}
                                        </Button>
                                    </div>
                                ))}
                        </Rodiv>
                    )}
                </Rodiv>
                {[lastMonth, thisMonth].map(m => <ProjectMonth key={m.getTime()} month={m} user={user} project={project} projectId={projectId} />)}
                <Fab
                    style={{ color: 'white' }}
                    size={'small'}
                    variant={atWork ? 'extended' : null}
                    onClick={this.handleClick}
                    color={atWork ? 'secondary' : 'primary'}>
                    {atWork ? (
                        <React.Fragment>
                            <PauseCircleOutline /> {timeAtWork}
                        </React.Fragment>
                    ) : (
                        <PlayCircleOutline />
                    )}
                </Fab>
            </React.Fragment>
        );
    };
}

export default Project;
