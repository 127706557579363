import React from 'react';
import Typography from '@material-ui/core/Typography';

const Rodiv = ({ children, ...props }) => (
    <Typography {...props} component={'div'}>
        {children}
    </Typography>
);

export default Rodiv;
