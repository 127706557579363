import React from 'react';
import MuiButton from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Project from './lib/Project/Project';
import Rodiv from './lib/Rodiv/Rodiv';
import Sum from './lib/Sum/Sum';
import UserMenu from './lib/UserMenu/UserMenu';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import StartPage from "./lib/StartPage/StartPage";

const styles = {
    grid: {
        display: 'grid',
        gap: '20px 40px',
        gridTemplateColumns: 'auto auto auto auto',
    },
};

class App extends React.Component {
    state = {
        projects: {},
    };

    handleTagSelected = projectId => tag => {
        const { user } = this.state;
        window.firebase
            .database()
            .ref(`/users/${user.uid}/projects/${projectId}/selectedTag`)
            .set(tag);
    };

    handleClearSelectedTag = projectId => () => {
        const { user } = this.state;
        window.firebase
            .database()
            .ref(`/users/${user.uid}/projects/${projectId}/selectedTag`)
            .set(null);
    };

    handleWorkload = projectId => workload => {
        const { projects, user } = this.state;
        window.firebase
            .database()
            .ref(`/users/${user.uid}/projects/${projectId}/workload/${workload[0]}`)
            .set({ onduty: workload[1], tag: projects[projectId].selectedTag || null });
    };

    render = () => {
        const {user} = this.state;
        const { projects } = this.state;
        const now = new Date();
        const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const monthName = now.toLocaleString('sv-se', { month: 'long' });
        const lastMonthName = lastMonth.toLocaleString('sv-se', { month: 'long' });
        return (
            <React.Fragment>
                <AppBar position={'static'}>
                    <Toolbar style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                        <div>Time is Money</div>
                        {this.state.user ? (
                            <UserMenu onLogout={this.handleLoginClick} user={this.state.user} />
                        ) : (
                            <MuiButton variant={'contained'} onClick={this.handleLoginClick}>
                                {this.state.user ? 'Logga ut' : 'Logga in'}
                            </MuiButton>
                        )}
                    </Toolbar>
                </AppBar>
                {user ? (
                    <Paper style={{padding: 16}}>
                        <Rodiv style={styles.grid}>
                            <div/>
                            {[lastMonthName, monthName].map((name, i) => (
                                <Rodiv
                                    key={i}
                                    style={{
                                        textTransform: 'capitalize',
                                        fontWeight: 'bold',
                                    }}
                                    align={'right'}
                                    variant={'caption'}>
                                    {name}
                                </Rodiv>
                            ))}
                            <div/>
                            {Object.entries(projects).map(([key, value]) => (
                                <Project
                                    key={key}
                                    user={user}
                                    projectId={key}
                                    onWorkload={this.handleWorkload(key)}
                                    onTagSelected={this.handleTagSelected(key)}
                                    onClearSelectedTag={this.handleClearSelectedTag(key)}
                                    project={value}
                                />
                            ))}
                            <Sum projects={projects}/>
                        </Rodiv>
                    </Paper>
                ) : (
                    <StartPage/>
                )}
            </React.Fragment>
        );
    };

    handleLoginClick = async () => {
        if (this.state.user) {
            window.firebase.auth().signOut();

            return;
        }
        let provider = new window.firebase.auth.GoogleAuthProvider();
        const result = await window.firebase.auth().signInWithPopup(provider);
        var user = result.user;
        this.user = user;
    };

    handleSnapshot = snapshot => {
        this.setState({ projects: snapshot.val() });
    };

    unsubscribe = null;

    componentWillUnmount = () => {
        this.unsubscribe();
    };

    getProjectsRef = () => {
        return this.state.user && `/users/${this.state.user.uid}/projects`;
    };

    componentDidMount = () => {
        let projectsRef;
        this.unsubscribe = window.firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.setState({user}, () => {
                    window.firebase
                        .database()
                        .ref(projectsRef = this.getProjectsRef())
                        .on('value', this.handleSnapshot);
                });
            } else {
                this.state.user &&
                    window.firebase
                        .database()
                        .ref(projectsRef)
                        .off('value');
                this.setState({ user: false, projects: {} });
            }
        });
    };
}

export default App;
