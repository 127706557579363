import React from 'react';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import Dialog from '@material-ui/core/Dialog/Dialog';
import Typography from '@material-ui/core/Typography/Typography';

const dayStyles = {
    date: { color: 'grey', textAlign: 'right' },
    hours: { textAlign: 'center', fontSize: '2rem' },
};

const Day = withStyles(dayStyles)(({ day, ms, month, classes }) => {
    let date = new Date(day);
    let hours = Math.round(ms / (1000 * 60 * 60));
    return (
        <React.Fragment>
            <div className={classes.date}>{date.getDate()}</div>
            <div className={classes.hours}>{!isNaN(hours) && hours}</div>
        </React.Fragment>
    );
});

const styles = theme => ({
    base: {
        display: 'flex',
        placeContent: 'center',
        placeItems: 'center',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,.2)',
    },
    day: {
        display: 'flex',
        flexDirection: 'column',
        borderTop: '1px solid gray',
        borderLeft: '1px solid gray',
        padding: '2px',
    },
    calendar: {
        backgroundColor: 'white',
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 70px)',
        gridTemplateRows: 'auto repeat(5, 70px)',
        borderRight: '1px solid gray',
        borderBottom: '1px solid gray',
    },
    monthName: {
        color: theme.palette.getContrastText(theme.palette.secondary.main),
        textTransform: 'capitalize',
    }
});

const Calendar = withStyles(styles)(
    class extends React.Component {
        render = () => {
            const { month, daysBreakdown, onClose: handleClick, classes } = this.props;

            let currentDate = new Date(month);
            let nextMonth = new Date(month);
            nextMonth.setMonth(nextMonth.getMonth() + 1);
            let datesInMonth = [];

            let dayOffset = currentDate.getDay() === 0 ? 7 : currentDate.getDay() - 1;

            currentDate.setDate(currentDate.getDate() - dayOffset);

            while (currentDate < nextMonth) {
                let day = `${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${(
                    '0' + currentDate.getDate()
                ).slice(-2)}`;
                datesInMonth.push(day);
                currentDate.setDate(currentDate.getDate() + 1);
            }

            return (
                <Dialog open={true}>
                    <AppBar position={'static'}>
                        <Toolbar style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Typography className={classes.monthName}>{currentDate.toLocaleString('sv-se', { month: 'long' })}</Typography>
                            <Button variant={'contained'} onClick={handleClick}>
                                Close
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.calendar}>
                        <div>Mo</div>
                        <div>Tu</div>
                        <div>We</div>
                        <div>Th</div>
                        <div>Fr</div>
                        <div>Sa</div>
                        <div>Su</div>
                        {datesInMonth.map(day => (
                            <div className={classes.day} key={day}>
                                <Day day={day} ms={daysBreakdown[day]} month={month} />
                            </div>
                        ))}
                    </div>
                </Dialog>
            );
        };
    },
);

export default Calendar;
