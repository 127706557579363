import Rodiv from '../Rodiv/Rodiv';
import React from 'react';
import Money from '../Money/Money';
import Button from '@material-ui/core/Button/Button';
import WorkloadEditor from './WorkloadEditor';

class ProjectMonth extends React.Component {
    render = () => {
        const { month, project, projectId, user } = this.props;
        let monthTotal = 0;
        let prevWorkload;
        let workloadPairs = [];

        if (!project.workload) {
            return <Rodiv />;
        }
        Object.entries(project.workload).forEach(([_time, { onduty: atWork }]) => {
            let time = parseInt(_time);
            if (!prevWorkload) {
                prevWorkload = { time, atWork };
                return;
            }
            if (prevWorkload.atWork && !atWork) {
                const workedMs = time - prevWorkload.time;
                let workedMonth = new Date(time);
                if (workedMonth > month && workedMonth < new Date(month.getFullYear(), month.getMonth() + 1)) {
                    monthTotal += workedMs;
                    workloadPairs.push({ start: prevWorkload, end: { time } });
                }
                prevWorkload = null;
            }
        });
        const monthHours = monthTotal / (1000 * 60 * 60);
        let money = monthHours * project.rate;
        money = Math.round(money);
        return (
            <WorkloadEditor
                buttonComponent={() => (
                    <React.Fragment>
                        <Rodiv align={'right'} variant={'subtitle2'}>
                            {Math.round(monthHours * 10) / 10}
                        </Rodiv>
                        <Rodiv align={'right'} variant={'caption'}>
                            <Money>{money}</Money>
                        </Rodiv>
                    </React.Fragment>
                )}
                workloadPairs={workloadPairs}
                project={project}
                projectId={projectId}
                user={user}
            />
        );
    };
}

export default ProjectMonth;
