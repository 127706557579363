import React from 'react';
import Paper from '@material-ui/core/Paper/Paper';
import Typography from '@material-ui/core/Typography/Typography';

class StartPage extends React.Component {
    render = () => {
        return (
            <Paper>
                <Typography align={'center'}>You just found your way to the simplest tool for keeping track of multiple projects at once.</Typography>
                <Typography align={'center'}>Login with your Google account to get started!</Typography>
                <Typography align={'center'}>Are you freelancing or have shitloads of small projects that you make money from by the hour?</Typography>
                {/*<Typography align={'center'}>And it's pretty nice to see the money counting!</Typography>*/}
            </Paper>
        );
    };
}

export default StartPage;
