import Calendar from "../Calendar/Calendar";
import Rodiv from "../Rodiv/Rodiv";
import Money from "../Money/Money";
import Button from '@material-ui/core/Link';
import React from "react";

class Sum extends React.Component {
    state = {
        selectedMonth: false,
    };

    showCalendar = month => () => {
        this.setState({ selectedMonth: month });
    };

    hideCalendar = () => {
        this.setState({ selectedMonth: false });
    };

    render = () => {
        const { projects } = this.props;
        const { selectedMonth } = this.state;
        const date = new Date();
        const thisMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);

        let lastMonthTotal = 0;
        let thisMonthTotal = 0;
        let lastMonthMoney = 0;
        let thisMonthMoney = 0;

        let daysBreakdown = {};

        Object.entries(projects).forEach(([, project]) => {
            let prevWorkload;
            if (!project.workload) {
                return;
            }
            Object.entries(project.workload).forEach(([_time, { onduty: atWork }]) => {
                let time = parseInt(_time);
                if (!prevWorkload) {
                    prevWorkload = { time, atWork };
                    return;
                }
                if (prevWorkload.atWork && !atWork) {
                    const workedMs = time - prevWorkload.time;
                    const workEnded = new Date(prevWorkload.time);
                    let workedMonth = new Date(time);
                    let day = `${workEnded.getFullYear()}-${('0' + (workEnded.getMonth() + 1)).slice(-2)}-${(
                        '0' + workEnded.getDate()
                    ).slice(-2)}`;

                    if (!daysBreakdown[day]) {
                        daysBreakdown[day] = 0;
                    }

                    daysBreakdown[day] += workedMs;
                    if (
                        workedMonth > lastMonth &&
                        workedMonth < new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1)
                    ) {
                        lastMonthTotal += workedMs;
                        lastMonthMoney += workedMs / 3.6e6 * project.rate;
                    }
                    if (
                        workedMonth > thisMonth &&
                        workedMonth < new Date(thisMonth.getFullYear(), thisMonth.getMonth() + 1)
                    ) {
                        thisMonthTotal += workedMs;
                        thisMonthMoney += workedMs / 3.6e6 * project.rate;
                    }
                    prevWorkload = null;
                }
            });
        });

        const lastMonthHours = lastMonthTotal / (1000 * 60 * 60);
        const thisMonthHours = thisMonthTotal / (1000 * 60 * 60);

        return (
            <React.Fragment>
                <Rodiv />
                <Rodiv>
                    <Rodiv align={'right'} variant={'subtitle2'}>
                        <Button onClick={this.showCalendar(lastMonth)}>O</Button>
                        {Math.round(lastMonthHours * 10) / 10}
                    </Rodiv>
                    <Rodiv align={'right'} variant={'caption'}>
                        <Money>{lastMonthMoney}</Money>
                    </Rodiv>
                </Rodiv>
                <Rodiv>
                    <Rodiv align={'right'} variant={'subtitle2'}>
                        <Button onClick={this.showCalendar(thisMonth)}>O</Button>
                        {Math.round(thisMonthHours * 10) / 10}
                    </Rodiv>
                    <Rodiv align={'right'} variant={'caption'}>
                        <Money>{thisMonthMoney}</Money>
                    </Rodiv>
                </Rodiv>
                {selectedMonth && (
                    <Calendar onClose={this.hideCalendar} month={selectedMonth} daysBreakdown={daysBreakdown} />
                )}
            </React.Fragment>
        );
    };
}
export default Sum;
